var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"forgot-password-container is-flex is-align-items-center is-justify-content-center"},[_c(_vm.randomiseSpinner,{directives:[{name:"show",rawName:"v-show",value:(_vm.$showSpinnerRequestCount > 0),expression:"$showSpinnerRequestCount > 0"}],tag:"component",attrs:{"message":"Loading..."}}),_c('div',{staticClass:"box"},[_vm._m(0),_vm._m(1),(!_vm.isRequestComplete)?_c('div',[_c('div',{staticClass:"control mb-6"},[_c('div',{staticClass:"field"},[_c('label',{staticClass:"label is-required-label"},[_vm._v("Username")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.username),expression:"username"}],staticClass:"input",attrs:{"type":"text","placeholder":"Username"},domProps:{"value":(_vm.username)},on:{"input":function($event){if($event.target.composing)return;_vm.username=$event.target.value}}}),(_vm.$v.username.$dirty && !_vm.$v.username.required)?_c('span',{staticClass:"help is-danger ml-1"},[_vm._v("Required")]):_vm._e()]),_vm._m(2)]),_c('div',{staticClass:"control mb-2 is-flex is-justify-content-space-between"},[_c('button',{staticClass:"button",on:{"click":_vm.cancel}},[_vm._m(3),_vm._v(" Cancel")]),_c('button',{staticClass:"button is-primary",on:{"click":_vm.sendEmail}},[_vm._m(4),_vm._v(" Send Email")])])]):_vm._e(),(_vm.isRequestComplete)?_c('div',[_c('notification-box',{staticClass:"mb-6",attrs:{"type":_vm.notificationType}},[_c('p',{staticClass:"is-size-7",attrs:{"slot":"text-content"},slot:"text-content"},[_vm._v(_vm._s(_vm.message))])]),_c('div',{staticClass:"control is-flex is-justify-content-end"},[_c('button',{staticClass:"button is-primary",on:{"click":_vm.cancel}},[_vm._m(5),_vm._v(" Back to login ")])])],1):_vm._e()])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"control mb-5 is-flex is-justify-content-center"},[_c('img',{staticStyle:{"max-height":"2.25rem"},attrs:{"src":require("@/assets/images/flexiquote-logo.svg"),"alt":"Flexiquote Logo","title":"Flexiquote Logo"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"control mb-5"},[_c('h4',{staticClass:"has-text-weight-medium is-size-5"},[_vm._v("Reset your password")]),_c('p',{staticClass:"has-text-grey is-size-7"},[_vm._v("Please enter your username and company ID, and we will email you instructions on how to reset your password. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"field"},[_c('label',{staticClass:"is-required-label"}),_c('label',{staticClass:"is-size-7"},[_vm._v("Required Fields")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon mr-1"},[_c('i',{staticClass:"mdi mdi-18px mdi-close"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon mr-1"},[_c('i',{staticClass:"mdi mdi-18px mdi-send"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon mr-1"},[_c('i',{staticClass:"mdi mdi-18px mdi-arrow-left"})])
}]

export { render, staticRenderFns }